import { useParams, useLocation } from "react-router-dom";
import { over } from "stompjs";
import SockJS from "sockjs-client";
import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../Store/Context/AuthContext";
import Navigation from "../../Components/Common Components/Navigation/Navigation";
import styles from "./ChallengeLobbyStyles.module.css";
import questionMarkImage from "../../Store/Images/ChallengeLobbyImages/questionMarkImage.png";
import Box from "../../Components/Common Components/Box/Box";
import ChallengeProblemPage from "../ChallengeProblemPage/ChallengeProblemPage";
import UserScoringPanel from "../../Components/ChallangePageComponents/UserScoringPanel/UserScoringPanel";
import EndPoints from "../../Store/API/EndPoints.json";

var stompClient;

const ChallengeLobby = () => {
  const { id } = useParams();
  const authCtx = useContext(AuthContext);

  const [lobbyInformation, setLobbyInformation] = useState({
    started: false,
    problemId: 0,
  });

  const [usersList, setUsersList] = useState([
    { username: authCtx.username, image: authCtx.image, leader: false },
  ]);

  const [userData, setUserData] = useState(usersList.find(obj => obj.username === authCtx.username));

  const [maxNumberOfProblems, setMaxNumberOfProblems] = useState(0);

  const leavingGamingHandler = () => {
    stompClient.send(
      "/app/LeaveLobby",
      {},
      JSON.stringify({
        username: userData.username,
        lobbyId: id,
        image: authCtx.image,
        leader: userData.leader
      })
    );
    stompClient.disconnect();
  };

  useEffect(() => {
    return () => {
      leavingGamingHandler();
    };
  }, []);

  useEffect(() => {
    let localHost = "http://localhost:8081/ws";
    let liveServer = "https://codenemy-websocket.herokuapp.com/ws";

    let Sock = new SockJS(liveServer);
    stompClient = over(Sock);
    stompClient.debug = null;
    
    stompClient.connect(
      {},
      () => {
        setUserData({
          ...userData,
          lobbyId: id,
          lobbyHost: userData.leader,
          connected: true,
        });

        stompClient.subscribe(
          "/lobby/public/getAll/" + id,
          onAllMembersReceived
        );

        stompClient.subscribe(
          "/lobby/public/lobbyStarted/" + id,
          onGameStarted
        );

        stompClient.send(
          "/app/JoinLobby",
          {},
          JSON.stringify({
            username: userData.username,
            lobbyId: id,
            image: authCtx.image,
            leader: userData.leader,
          })
        );

        stompClient.send("/app/RetrieveAllEndUsers", {}, id);
      },
      onError
    );
  }, [id]);

  if (userData.leader && maxNumberOfProblems === 0) {
    fetch(EndPoints.ProblemCount, {
      method: "GET",
    })
      .then((data) => {
        if (data.status === 200) {
          return data.json();
        }
      })
      .then((data) => {
        setMaxNumberOfProblems(data);
      });
  }

  const onError = (err) => {};

  const onAllMembersReceived = (payload) => {
    const jsonValue = JSON.parse(payload.body);
    setUsersList(jsonValue);
    setUserData(jsonValue.find(user => user.username === authCtx.username));
  };

  const onGameStarted = (payload) => {
    setLobbyInformation(JSON.parse(payload.body));
    // Need this channel to also produce a problem id.
  };

  const startGameHandler = () => {
    stompClient.send(
      "/app/StartLobby",
      {},
      JSON.stringify({
        lobbyId: id,
        maxProblems: maxNumberOfProblems,
      })
    );
  };

  const updatePercentageHandler = (percentage, lang) => {
    stompClient.send(
      "/app/UpdatePercentage",
      {},
      JSON.stringify({
        lobbyId: id,
        username: userData.username,
        percentage: percentage,
        language: lang,
      })
    );
  };

  // check if the this current user has finished.
  // Filter the array??
  const currentUser = usersList.filter(
    (user) => user.username === authCtx.username
  );

  if (
    currentUser[0].finishedStatus === true &&
    lobbyInformation.started === true
  ) {
    return (
      <React.Fragment>
        <Navigation />
        <div className={styles.userScoringPanelContainer}>
          <UserScoringPanel users={usersList} />
        </div>
      </React.Fragment>
    );
  }

  if (lobbyInformation.started === true) {
    return (
      <ChallengeProblemPage
        users={usersList}
        updatePercentageHandler={updatePercentageHandler}
        problemId={lobbyInformation.problemId}
      />
    );
  }

  const leaderFromLobby = usersList.find(user => user.leader === true);

  return (
    <React.Fragment>
      <Navigation />
      <div className={styles.container}>
        <div className={styles.urlSection}>
          <Box title="Your Lobby Information" className={styles.urlBox}>
            <h2> URL : {window.location.href}</h2>
            {leaderFromLobby && <h2>Lobby Leader : {leaderFromLobby?.username} </h2>}
            <h2> Lobby ID : {id}</h2>
            <p>
              {" "}
              Your friends can join this lobby by inputting the Lobby ID above
              in the Challange page Or you can send this URL to them.
            </p>
          </Box>
          <h1> Players in the lobby</h1>
        </div>
        <div className={styles.playersSection}>
          {usersList.map((user) => {
            return (
              <React.Fragment key={user.username}>
                <div className={styles.player}>
                  <img
                    src={`data:image/png;base64,${user.image}`}
                    alt="profile pic"
                  />
                  <p> {user.username} </p>
                </div>
              </React.Fragment>
            );
          })}
          {usersList.length === 1 && (
            <div className={styles.player}>
              <img
                src={questionMarkImage}
                alt="profile pic"
                style={{ color: "#fff" }}
              />
              <p> ... </p>
            </div>
          )}
        </div>
        {userData.leader === true && (
          <button
            type="button"
            onClick={startGameHandler}
            disabled={usersList.length <= 1}
          >
            Start Lobby
          </button>
        )}
        {!userData.leader && <h2> Waiting for host to start</h2>}
      </div>
    </React.Fragment>
  );
};

export default ChallengeLobby;
