import React, { useContext, useState } from "react";
import Styles from "./outputbarstyles.module.css";
import TestCaseResponse from "./TestCaseResponse";
import AuthContext from "../../../Store/Context/AuthContext";
import EndPoints from "../../../Store/API/EndPoints.json";
import SubmissionResponse from "./SubmissionResponse";

const Output = (props) => {
  const authCtx = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [testCaseResponse, setResponse] = useState(null);
  const [isSubmission, setIsSubmission] = useState(false);

  const runCodeHandler = () => {
    setIsLoading(true);
    setIsSubmission(false);
    sendRunCodeRequest();
  };

  const submitCodeHandler = () => {
    setIsLoading(true);
    setIsSubmission(true);
    sendSubmitCodeRequest();
  };

  const sendRunCodeRequest = () => {
    const jsonBody = {
      language: props.currentLanguage,
      script: props.code,
      problemId: props.problem.id,
      username: authCtx.username,
      userId: authCtx.userId,
    };

    fetch(EndPoints.RunCode, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(jsonBody),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
        }
      })
      .then((data) => {
        if (data.error.length > 0) {
          setIsLoading(false);
          setIsError(true);
          setResponse(data);
        }
        else {
          setResponse(data);
          setIsLoading(false);
          setIsError(false);
        }
      })
      .catch((err) => {});
  };

  const sendSubmitCodeRequest = () => {
    var url = EndPoints.SubmitCode;
    if (props.competition) {
      url = EndPoints.SubmitCodeChallenge;
    }

    const jsonBody = {
      language: props.currentLanguage,
      script: props.code,
      problemId: props.problem.id,
      username: authCtx.username,
      userId: authCtx.userId,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(jsonBody),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
        }
      })
      .then((data) => {
        authCtx.problemPointsHandler(data.newProblemPoints);
        setResponse(data);
        setIsLoading(false);
        if (data.percentage === 100) {
          props.updateSolved(true);
        }
        if (props.competition) {
          props.updatePercentageHandler(data.percentage, props.currentLanguage);
        }
      })
      .catch((err) => {});
  };

  return (
    <React.Fragment>
      <div className={Styles.buttonSection}>
        <button onClick={runCodeHandler} disabled={!authCtx.isLoggedIn}>
          Run code
        </button>
        <button
          id={Styles.submitBtn}
          onClick={submitCodeHandler}
          disabled={!authCtx.isLoggedIn}
        >
          Submit code
        </button>
      </div>
      <div>
        {!isSubmission && (
          <TestCaseResponse
            isLoading={isLoading}
            testCaseResponse={testCaseResponse}
          />
        )}
        {isSubmission && (
          <SubmissionResponse
            isLoading={isLoading}
            submissionResponse={testCaseResponse}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Output;
