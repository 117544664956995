import styles from "../LogInFormStyles.module.css";
import { FaUserAlt, FaLock } from "react-icons/fa";
import enemyImage from "../../../Store/Images/LogInPageImages/FormImage.png";
import React from "react";

const RegisterForm = (props) => {
  return (
    <div className={styles.form}>
      <form className={styles.formLeftSide}>
        <div className={styles.titleSection}>
          <h1> Codenemy </h1>
          <h3> For Developers </h3>
          <h5>
            Practise coding, compete, prepare for interviews and get hired
          </h5>
        </div>
        <div className={styles.formSection}>
          <h1> {props.formLabel} </h1>
          <h5>
            Please enter information for all fields marked with a *
          </h5>
          <label>
            <FaUserAlt />
          </label>
          <input
            type="text"
            placeholder="Email*"
            onChange={props.emailHandler}
          />
          {props.emailError && (
            <React.Fragment>
              <ul className={styles.error}>
                <li>Email must be valid</li>
              </ul>
            </React.Fragment>
          )}
          <input
            type="text"
            placeholder="Username*"
            onChange={props.usernameHandler}
          />
          {props.userNameError && (
            <React.Fragment>
              <ul className={styles.error}>
                <p style={{ color: "#fff" }}>
                  Username must meet the following conditions :
                </p>
                <li>Minimum 8 characters long</li>
                <li>No white spaces</li>
                <li>Only includes letters or numbers</li>
              </ul>
            </React.Fragment>
          )}
          <input
            type="text"
            placeholder="Name"
            onChange={(event) => props.setName(event.target.value)}
          />
          <input
            type="text"
            placeholder="Bio" 
            onChange={(event) => props.setBio(event.target.value)}
          />
          <input
            type="text"
            placeholder="Profession"
            onChange={(event) => props.setProfession(event.target.value)}
          />
          <input
            type="text"
            placeholder="Location"
            onChange={(event) => props.setLocation(event.target.value)}
          />
          <label>
            <FaLock />
          </label>
          <input
            type="password"
            placeholder="Password*"
            onChange={props.passwordHandler}
          />
          {props.passwordError && (
            <React.Fragment>
              <ul className={styles.passwordError}>
                <p style={{ color: "#FFF" }}>
                  {" "}
                  Password must meet the following conditions :
                </p>
                <li>Minimum 8 characters long</li>
                <li>Atleast One uppercase character</li>
                <li>Atleast One lowercase character</li>
                <li>Atleast one number</li>
                <li>Atleast one special character</li>
              </ul>
            </React.Fragment>
          )}
          <label>Profile Picture</label>
          <input
            type="file"
            accept=".png,.jpg,.jpeg"
            onChange={props.imageHandler}
          />
          {props.children}
        </div>
      </form>
      <div className={styles.formRightSide}>
        <img src={enemyImage} alt="form" />
      </div>
    </div>
  );
};

export default RegisterForm;
