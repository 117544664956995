import React from "react";
import Styles from "./descriptionstyles.module.css";

const Description = (props) => {
  const difficultyClassName = difficultyClass(props.data.difficulty);

  return (
    <React.Fragment>
      <div className={Styles.titleSection}>
        <h2>{props.data.name}</h2>
        <h3 className={difficultyClassName}>{props.data.difficulty}</h3>
      </div>
      <div
        className={Styles.description}
        dangerouslySetInnerHTML={{ __html: props.data.description }}
      />
    </React.Fragment>
  );
};

function difficultyClass(difficulty) {
  if (difficulty === "Easy") return Styles.easy;
  else if (difficulty === "Medium") return Styles.medium;
  else return Styles.hard;
}

export default Description;
