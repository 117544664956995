import Navigation from "../../Components/Common Components/Navigation/Navigation";
import styles from "./LogInPageStyles.module.css";
import LogInForm from "../../Components/LogInRegisterPage/LogInRegisterForm";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const LogInPage = () => {
  const { state } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.container}>
      <Navigation />
      <LogInForm logIn={state.logIn}/>
    </div>
  );
};

export default LogInPage;
