import styles from "./HomePage.module.css";
import LandingSection from "../../Components/HomePageComponents/LandingSection/LandingSection";
import LearningSection from "../../Components/HomePageComponents/LearningSection/LearningSection";
import TargetSection from "../../Components/HomePageComponents/TargetSection/TargetSection";
import ReviewSection from "../../Components/HomePageComponents/ReviewSection/ReviewSection";
import Footer from "../../Components/Common Components/Footer/Footer";
import "@fontsource/ibm-plex-sans";
import React from "react";

const HomePage = () => {
  return (
    <React.Fragment>
      <div className={styles.container}>
        <LandingSection />
        <LearningSection/>
        <TargetSection/>
        <ReviewSection/>
        <Footer/>
      </div>
    </React.Fragment>
  );
};

export default HomePage;
