import styles from "./filterstyles.module.css";

const filters = [
  "All",
  "Array",
  "String",
  "Hash Table",
  "Dynamic Programming",
  "Math",
  "Sorting",
  "Greedy",
  "Depth-First Search",
  "Database",
  "Breadth-First Search",
  "Tree",
  "Binary Search",
  "Matrix",
  "Sliding Window"
];

const Filter = (props) => {
  const onFilterClicked = (newFilter) => {
    props.setFilter(newFilter);
  };

  return (
    <div className={styles.filters}>
      {filters.map((filter) => {
        if (filter === props.activeFilter) {
          return (
            <p
              id={styles.active}
              key={filter}
              onClick={() => onFilterClicked(filter)}
            >
              {filter}
            </p>
          );
        } else {
          return (
            <p key={filter} onClick={() => onFilterClicked(filter)}>
              {filter}
            </p>
          );
        }
      })}
    </div>
  );
};

export default Filter;
