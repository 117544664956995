import React from "react";
import styles from "./UserPanelStyles.module.css";

const UserPanel = (props) => {
  const listOfUsers = props.users;

  return (
    <div className={styles.userPanel}>
      {listOfUsers.map((user) => (
        <div className={`${styles.userBox} ${user.finishedStatus ? styles.finished : styles.notFinished}`} key={user.username}>
          <img src={`data:image/png;base64,${user.image}`} alt="profile pic" />
          <p> {user.username} </p>
        </div>
      ))}
    </div>
  );
};

export default UserPanel;
