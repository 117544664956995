import React from "react";
import styles from "./LogoStyles.module.css";

const Logo = (props) => {
    return (
        <span className={`${styles.logo} ${props.className}`} onClick={props.clickHandler}>
            {props.children}
        </span>
    );
}

export default Logo;