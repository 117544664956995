import styles from "../LogInFormStyles.module.css";
import { FaUserAlt, FaLock } from "react-icons/fa";
import enemyImage from "../../../Store/Images/LogInPageImages/FormImage.png";

const LogInForm = (props) => {
  return (
    <div className={styles.form}>
      <form className={styles.formLeftSide}>
        <div className={styles.titleSection}>
          <h1> Codenemy </h1>
          <h3> For Developers </h3>
          <h5>
            Practise coding, compete, prepare for interviews and get hired
          </h5>
        </div>
        <div className={styles.formSection}>
          <h1> {props.formLabel} </h1>
          <label>
            <FaUserAlt />
          </label>
          <input
            type="text"
            placeholder="Username"
            onChange={props.usernameHandler}
          />
          <label>
            <FaLock />
          </label>
          <input
            type="password"
            placeholder="Password"
            onChange={props.passwordHandler}
          />
          {props.incorrectDetailsError && (
            <p className={styles.error}>Incorrect Details</p>
          )}
          {props.children}
        </div>
      </form>
      <div className={styles.formRightSide}>
        <img src={enemyImage} alt="form" />
      </div>
    </div>
  );
};

export default LogInForm;
