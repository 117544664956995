import "./App.css";
import { useContext } from "react";
import HomePage from "./Pages/HomePage/HomePage";
import LogInPage from "./Pages/LogInPage/LogInPage";
import ChallengeLobby from "./Pages/ChallengeLobby/ChallengeLobby";
import ProblemPage from "./Pages/ProblemPage/ProblemPage";
import React from "react";
import AuthContext from "./Store/Context/AuthContext";
import { Routes, Route } from "react-router-dom";
import IndividualProblemPage from "./Pages/IndividualProblemPage/IndividualProblemPage";
import ChallengePage from "./Pages/ChallengePage/ChallengePage";
import ProfilePage from "./Pages/ProfilePage/ProfilePage";

function App() {
  const authCtx = useContext(AuthContext);

  return (
    <React.Fragment>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LogInPage />} />
          <Route path="/problems" element={<ProblemPage />} />
          <Route
            path="/problems/problem/:id"
            element={<IndividualProblemPage />}
          />
          {authCtx.isLoggedIn && (
            <React.Fragment>
              <Route path="/challenge" element={<ChallengePage />} />
              <Route path="/challenge/lobby/:id" element={<ChallengeLobby />} />
              <Route path="/user/profile" element={<ProfilePage />} />
            </React.Fragment>
          )}
        </Routes>
      </div>
    </React.Fragment>
  );
}

export default App;
