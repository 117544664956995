import styles from "./InformationPanel.module.css";
import Box from "../../Common Components/Box/Box";

const InformationPanel = () => {
  return (
    <div className={styles.InformationPanelSection}>
      <Box className={styles.InformationPanel} title={"Why Challenge others?"}>
        <h3>What's the purpose</h3>
        <p>
          Codenemy aims to test an engineer's knowledge surrounding efficiency,
          patterns and problem solving.
        </p>
        <h3> What's the process</h3>
        <p>
          You have the choice of matching up against friends to compete in
          solving problems or you can solve them in isolation.
        </p>
        <h3>What are the aims?</h3>
        <ul>
          <li>Improve problem solving skills</li>
          <li>Anaylse different solutions</li>
          <li>Practise technical interview questions</li>
          <li>Beat friends</li>
        </ul>
      </Box>
    </div>
  );
};

export default InformationPanel;
