import LoadingSpinner from "../../Common Components/LoadingSpinner/LoadingSpinner";
import Styles from "./outputbarstyles.module.css";

const SubmissionResponse = (props) => {
  if (props.isLoading) return <LoadingSpinner notFullSize={true} />;

  if (props.submissionResponse === null && !props.isLoading) {
    return;
  }

  var messageStyleObject = {};

  if (props.submissionResponse.percentage === 100)
    messageStyleObject = { color: "green" };
  else messageStyleObject = { color: "red" };

  if (props.submissionResponse.error != null) {
    return (
      <div className={Styles.responseSection}>
        <div className={Styles.subSection}>
          <h3 className={Styles.label}>Submission State</h3>
          <div className={Styles.resultDiv}>
            <h3 className={Styles.result} style={messageStyleObject}>
              {props.submissionResponse.percentage === 100
                ? "Correct"
                : "Incorrect - Code contained errors"}
            </h3>
            <h3 className={Styles.result} style={messageStyleObject}>
              {"Please run your code to debug it."}
            </h3>
          </div>
        </div>
        <div className={Styles.subSection}>
          <h3 className={Styles.label}>Percentage</h3>
          <div className={Styles.resultDiv}>
            <h3 className={Styles.result}>
              {props.submissionResponse.percentage}
            </h3>
          </div>
        </div>
        <div className={Styles.subSection}>
          <h3 className={Styles.label}>Points achieved</h3>
          <div className={Styles.resultDiv}>
            <h3 className={Styles.result}>{props.submissionResponse.points}</h3>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={Styles.responseSection}>
      <div className={Styles.subSection}>
        <h3 className={Styles.label}>Submission State</h3>
        <div className={Styles.resultDiv}>
          <h3 className={Styles.result} style={messageStyleObject}>
            {props.submissionResponse.percentage === 100
              ? "Correct - All Test Cases Passed"
              : "Incorrect - All Test Cases Did Not Pass"}
          </h3>
        </div>
      </div>
      <div className={Styles.subSection}>
        <h3 className={Styles.label}>Percentage</h3>
        <div className={Styles.resultDiv}>
          <h3 className={Styles.result}>
            {props.submissionResponse.percentage}
          </h3>
        </div>
      </div>
      <div className={Styles.subSection}>
        <h3 className={Styles.label}>Points achieved</h3>
        <div className={Styles.resultDiv}>
          <h3 className={Styles.result}>{props.submissionResponse.points}</h3>
        </div>
      </div>
    </div>
  );
};

export default SubmissionResponse;
