import ReviewOneImage from "../../../Store/Images/HomePageImages/ReviewOneImage.jpg";
import styles from "./ReviewSectionStyle.module.css";
import { MdReviews } from "react-icons/md";
import Logo from "../../Common Components/Logo/Logo";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import React, { useState } from "react";

const reviewArr = [
  {
    review: "Extremely well done and an excellent example of mastery learning.",
    reviewer: "Samir Zafar",
    job: "Student",
    image: ReviewOneImage,
  },
  {
    review:
      "Extremely well done and an excellent example of mastery learningV2.",
    reviewer: "Samir ZafarV2",
    job: "StudentV2",
    image: ReviewOneImage,
  },
  {
    review:
      "Extremely well done and an excellent example of mastery learningV3.",
    reviewer: "Samir ZafarV3",
    job: "StudentV3",
    image: ReviewOneImage,
  },
  {
    review:
      "Extremely well done and an excellent example of mastery learningV4.",
    reviewer: "Samir ZafarV4",
    job: "StudentV4",
    image: ReviewOneImage,
  },
];

const ReviewSection = () => {
  const [counter, setCounter] = useState(0);

  const changeCounter = (increment) => {
    if (increment) {
      if (counter + 1 >= reviewArr.length) {
        return;
      } else {
        setCounter(counter + 1);
      }
    } else {
      if (counter - 0 <= 0) {
        return;
      } else {
        setCounter(counter - 1);
      }
    }
  };

  const reviewObject = reviewArr[counter];

  return (
    <div className={styles.gridFour}>
      <h1> Reviews </h1>
      <Logo>
        <MdReviews />
      </Logo>
      <div className={styles.innerGridFour}>
        <div className={styles.innerGridFour1}>
          <div className={styles.leftArrow}>
            <Logo className={styles.leftArrowLogo}>
              <BsArrowLeftCircleFill onClick={() => changeCounter(false)} />
            </Logo>
          </div>
          {reviewArr.map((review, index) => {
            return (
              <div className={index === counter ? styles.reviewText : styles.review} key={index}>
                {index === counter && (
                  <React.Fragment>
                    <h1>{reviewObject.review}</h1>
                    <img src={reviewObject.image} alt="review"></img>
                    <h3> {reviewObject.reviewer} </h3>
                    <h4> {reviewObject.job} </h4>
                  </React.Fragment>
                )}
              </div>
            );
          })}
          <div className={styles.rightArrow}>
            <Logo className={styles.rightArrowLogo}>
              <BsArrowRightCircleFill onClick={() => changeCounter(true)} />
            </Logo>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewSection;
