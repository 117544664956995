import {
  RiGithubFill,
  RiLinkedinBoxFill,
  RiInstagramLine,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import styles from "./FooterStyles.module.css";
import AuthContext from "../../../Store/Context/AuthContext";
import { useContext } from "react";

const Footer = () => {
  const authCtx = useContext(AuthContext);

  const navigate = useNavigate();

  return (
    <div className={styles.gridfour}>
      <footer>
        <div className={styles.footerGrid}>
          <div className={styles.footerLeftSide}>
            <h1> Codenemy </h1>
            <h2> Challenge yourself </h2>
          </div>
          <div className={styles.footerRightSide}>
            {authCtx.isLoggedIn && (
              <div className={styles.developerFooter}>
                <h3> Challenge </h3>
                <p onClick={() => navigate("/challenge")}> Challenge players</p>
              </div>
            )}
            <div className={styles.developerFooter}>
              <h3> Problems </h3>
              <p onClick={() => navigate("/problems")}> Solve Problems </p>
            </div>
            {authCtx.isLoggedIn && (
              <div className={styles.developerFooter}>
                <h3> Profile </h3>
                <p onClick={() => navigate("/user/profile")}> View Profile</p>
              </div>
            )}
          </div>
        </div>
        <div className={styles.credits}>
          <div className={styles.creditLeft}>
            <p>© Chaudhary Zafar</p>
            <p>API</p>
            <p>About</p>
          </div>
          <div className={styles.creditRight}>
            <a href="https://www.linkedin.com/in/samir-zafar-6b59111bb/">
              <RiLinkedinBoxFill />
            </a>
            <a href="https://github.com/ChaudharySamirZafar?tab=repositories">
              <RiGithubFill />
            </a>
            <a href="https://www.instagram.com/samirr.zafarr/">
              <RiInstagramLine />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
