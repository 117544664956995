import Box from "../../Common Components/Box/Box";
import styles from "./JoinCreatePanelStyles.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const JoinCreatePanel = () => {
  const [lobbyId, setLobbyId] = useState("");

  const navigate = useNavigate();

  const createLobbyHandler = () => {
    var uuid = require("uuid");

    navigate("/challenge/lobby/" + uuid.v4().slice(0,6), {
      state: {
        lobbyLeader: true,
      },
    });
  };

  const joinLobbyHandler = () => {
    navigate("/challenge/lobby/" + lobbyId, {
      state: {
        lobbyLeader: false,
      },
    });
  };

  return (
    <div className={styles.JoinCreatePanelSection}>
      <Box title="Join Or Create" className={styles.JoinCreatePanel}>
        <div>
          <div className={styles.createSection}>
            <h3>Create</h3>
            <button onClick={createLobbyHandler}> Create </button>
          </div>
          <div className={styles.joinSection}>
            <h3>JoinLobby</h3>
            <label> Lobby ID </label>
            <input type="text" placeholder="Enter Lobby ID" onChange={event => setLobbyId(event.target.value)}/>
            <button onClick={joinLobbyHandler} disabled={lobbyId.length === 0}>Join Lobby</button>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default JoinCreatePanel;
