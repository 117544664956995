import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-ruby";
import "ace-builds/src-noconflict/mode-php";
import "ace-builds/src-noconflict/mode-typescript";
import "ace-builds/src-noconflict/mode-erlang";
import "ace-builds/src-noconflict/mode-dart";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import "./idestyles.css";
import { useEffect } from "react";

const IDE = (props) => {
  var localStorageCodeValue = props.competition
    ? null
    : localStorage.getItem(
        "codeFor-" + props.currentProblemName + props.currentLanguage
      );

  var ideValue = localStorageCodeValue;

  if (localStorageCodeValue === null) ideValue = props.code;

  useEffect(() => {
    props.updateProblemCode(ideValue);
  });

  const onChangeHandler = (value) => {
    if (!props.competition) {
      localStorage.setItem(
        "codeFor-" + props.currentProblemName + props.currentLanguage,
        value
      );
    }
    props.updateProblemCode(value);
  };

  var ideLang = props.currentLanguage;

  if (ideLang === "python3") ideLang = "python";
  else if (ideLang === "Java") ideLang = "java";

  return (
    <div className="ideSection">
      <AceEditor
        width="100%"
        className="editor"
        mode={ideLang}
        theme="monokai"
        name="blah2"
        maxLines={30}
        minLines={30}
        onChange={(value) => onChangeHandler(value)}
        fontSize={14}
        showPrintMargin={false}
        showGutter={true}
        wrapEnabled
        highlightActiveLine={true}
        value={ideValue}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
    </div>
  );
};

export default IDE;
