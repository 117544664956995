import React, { useContext, useState } from "react";
import "./NavigationStyles.css";
import AuthContext from "../../../Store/Context/AuthContext";
import { useNavigate } from "react-router-dom";

const Navigation = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleNavbarVisibility = () => {
    setIsNavbarExpanded(!isNavbarExpanded);
  };

  return (
    <header id="navbar">
      <nav className="navbar-container container">
        <h3 onClick={() => navigate("/")} className="home-link">
          Codenemy
        </h3>
        <button
          type="button"
          id="navbar-toggle"
          aria-controls="navbar-menu"
          aria-label="Toggle menu"
          aria-expanded={isNavbarExpanded}
          onClick={toggleNavbarVisibility}
        >
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
        <div id="navbar-menu" aria-labelledby="navbar-toggle">
          <ul className="navbar-links">
            <li className="navbar-item" onClick={() => navigate("/problems")}>
              <h4 className="navbar-link">Problems</h4>
            </li>
            {authContext.isLoggedIn && (
              <li
                className="navbar-item"
                onClick={() => navigate("/challenge")}
              >
                <h4 className="navbar-link">Challenge</h4>
              </li>
            )}
            {authContext.isLoggedIn && (
              <React.Fragment>
                <li
                  className="navbar-item"
                  id="profile"
                  onClick={() => {
                    navigate("/user/profile");
                  }}
                >
                  <h4 className="navbar-link">My Profile</h4>
                </li>
                <li
                  className="navbar-item"
                  onClick={() => {
                    authContext.logout();
                    navigate("/");
                  }}
                  id="logOut"
                >
                  <h4 className="navbar-link">Log Out</h4>
                </li>
              </React.Fragment>
            )}

            {!authContext.isLoggedIn && (
              <React.Fragment>
                <li className="navbar-item">
                  <button
                    className="navbar-link"
                    onClick={() =>
                      navigate("/login", { state: { logIn: true } })
                    }
                  >
                    Sign In
                  </button>
                </li>
                <li className="navbar-item">
                  <button
                    className="navbar-link"
                    onClick={() =>
                      navigate("/login", { state: { logIn: false } })
                    }
                  >
                    Register
                  </button>
                </li>
              </React.Fragment>
            )}
            {authContext.isLoggedIn && (
              <li className="navbar-item">
                <img
                  className="userIcon"
                  src={`data:image/png;base64,${authContext.image}`}
                  alt="Profile"
                  onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                />
                <div
                  className="dropDown"
                  style={{
                    display: isDropdownVisible ? "block" : "none",
                  }}
                >
                  <ul>
                    <li
                      onClick={() => {
                        navigate("/user/profile");
                      }}
                    >
                      <h4>My Profile</h4>
                    </li>
                    <li
                      onClick={() => {
                        authContext.logout();
                        navigate("/");
                      }}
                    >
                      <h4>Log Out</h4>
                    </li>
                  </ul>
                </div>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Navigation;
