import React, { useContext, useState } from "react";
import Navigation from "../../Components/Common Components/Navigation/Navigation";
import styles from "./ProfilePageStyles.module.css";
import Box from "../../Components/Common Components/Box/Box";
import AuthContext from "../../Store/Context/AuthContext";
import EndPoints from "../../Store/API/EndPoints.json";
import LoadingSpinner from "../../Components/Common Components/LoadingSpinner/LoadingSpinner";
import { useEffect } from "react";

const ProfilePage = () => {
  const authCtx = useContext(AuthContext);

  const [newPassword, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [newEmail, setEmail] = useState("");
  const [newBio, setBio] = useState("");
  const [newProfession, setProfession] = useState("");
  const [newName, setName] = useState("");
  const [newLocation, setLocation] = useState("");

  const [userNameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formHandler = (event) => {
    event.preventDefault();

    if (
      !userNameError &&
      !passwordError &&
      !emailError &&
      (newPassword.length > 0 ||
        username.length > 0 ||
        newEmail.length > 0 ||
        newBio.length > 0 ||
        newProfession.length > 0 ||
        newName.length > 0 ||
        newLocation.length > 0)
    ) {
      setIsLoading(true);

      const url = EndPoints.ChangeDetails;

      const secondUrl = url
        .replace(2, username)
        .replace(3, newPassword)
        .replace(1, 31);

      fetch(secondUrl, {
        body: JSON.stringify({
          id: authCtx.userId,
          password: newPassword,
          username: username,
          image: authCtx.image,
          email: newEmail,
          name: newName,
          profession: newProfession,
          bio: newBio,
          location: newLocation,
        }),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          authCtx.login(data.access_token, data.userModel);
          setSuccess(true);
          setIsLoading(false);
        });
    }
  };

  const passwordHandler = (event) => {
    const newPassword = event.target.value;

    setPassword(newPassword);

    const validRegex =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

    if (!newPassword.match(validRegex) && newPassword.length > 0) {
      setPasswordError(true);
    } else setPasswordError(false);
  };

  const usernameHandler = (event) => {
    const newUsername = event.target.value;

    setUsername(newUsername);
    if (newUsername.length < 8 && newUsername.length > 0) {
      setUsernameError(true);
    } else setUsernameError(false);
  };

  const emailHandler = (event) => {
    const newEmail = event.target.value;

    setEmail(newEmail);
    var format =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (newEmail.includes(" ") || !format.test(newEmail)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  return (
    <React.Fragment>
      <Navigation />
      <div className={styles.container}>
        <div>
          <Box
            className={styles.yourDetails}
            description={authCtx.username}
            alignMiddle={true}
          >
            <img
              src={`data:image/png;base64,${authCtx.image}`}
              alt="profile"
              className={styles.profilePic}
            />
            <button type="submit" disabled={userNameError || passwordError}>
              Edit Profile Picture
            </button>
          </Box>
        </div>
        <div>
          <Box className={styles.yourDetails} title="Your Personal Details">
            {isLoading && <LoadingSpinner notFullSize />}
            {!isLoading && (
              <React.Fragment>
                <div className={styles.changingDetails}>
                  <form
                    onSubmit={formHandler}
                    className={styles.changingDetailsForm}
                  >
                    <h3> Name : {authCtx.name} </h3>
                    <input
                      type="text"
                      placeholder="New Name"
                      onChange={(event) => setName(event.target.value)}
                    />
                    <h3> Username : {authCtx.username} </h3>
                    <input
                      type="text"
                      placeholder="New Username"
                      onChange={usernameHandler}
                    />
                    {userNameError && (
                      <p className={styles.error}>
                        Username has to have a minimum of 8 characters{" "}
                      </p>
                    )}
                    <input
                      type="password"
                      placeholder="New Password"
                      onChange={passwordHandler}
                    />
                    {passwordError && (
                      <React.Fragment>
                        Password must meet the following conditions
                        <ul className={styles.error}>
                          <li>Minimum 8 characters long</li>
                          <li>Atleast One uppercase character</li>
                          <li>Atleast One lowercase character</li>
                          <li>Atleast one number</li>
                          <li>Atleast one special character</li>
                        </ul>
                      </React.Fragment>
                    )}
                    <h3> Bio : {authCtx.bio} </h3>
                    <input
                      type="text"
                      placeholder="New Bio"
                      onChange={(event) => setBio(event.target.value)}
                    />
                    <h3> Email : {authCtx.email} </h3>
                    <input
                      type="text"
                      placeholder="New Email"
                      onChange={emailHandler}
                    />
                    {emailError && (
                      <React.Fragment>
                        <ul className={styles.error}>
                          <li>Email must be valid</li>
                        </ul>
                      </React.Fragment>
                    )}
                    <h3> Profession : {authCtx.profession} </h3>
                    <input
                      type="text"
                      placeholder="New Profession"
                      onChange={(event) => setProfession(event.target.value)}
                    />
                    <h3> Location : {authCtx.location} </h3>
                    <input
                      type="text"
                      placeholder="New Location"
                      onChange={(event) => setLocation(event.target.value)}
                    />
                    <button
                      type="submit"
                      disabled={userNameError || passwordError}
                    >
                      Save Changes
                    </button>
                  </form>
                  {success && (
                    <h3 className={styles.success}>
                      {" "}
                      Details successfully updated.
                    </h3>
                  )}
                </div>
              </React.Fragment>
            )}
          </Box>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProfilePage;
