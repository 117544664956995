import React, { useState } from "react";

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  login: (token, userModel) => {},
  logout: () => {},
  username: "",
  problemPoints : 0,
  competitionPoints  : 0,
  problemPointsHandler: (newPoints) => {},
  image: "",
  userId: 0,
  email: "",
  name: "",
  bio: "",
  profession: "",
  location: ""
});

export const AuthContextProvider = (props) => {
  const initalToken = localStorage.getItem('token');
  const [token, setToken] = useState(initalToken);

  const initalUsername = localStorage.getItem('username');
  const [username, setUserName] = useState(initalUsername);

  const initalProblemPoints = localStorage.getItem('problemPoints');
  const [problemPoints, setProblemPoints] = useState(initalProblemPoints);

  const initalCompetitionPoints = localStorage.getItem('competitionPoints');
  const [competitionPoints, setCompetitionPoints] = useState(initalCompetitionPoints);

  const initalImage = localStorage.getItem('image');
  const [image, setImage] = useState(initalImage);

  const initalUserId = localStorage.getItem('userId');
  const [userId, setUserId] = useState(initalUserId);

  const initalEmail = localStorage.getItem('email');
  const [email, setEmail] = useState(initalEmail);

  const initalName = localStorage.getItem('name');
  const [name, setName] = useState(initalName);

  const initalBio = localStorage.getItem('bio');
  const [bio, setBio] = useState(initalBio);

  const initalProfession = localStorage.getItem('profession');
  const [profession, setProfession] = useState(initalProfession);

  const initalLocation = localStorage.getItem('location');
  const [location, setLocation] = useState(initalLocation);

  const userIsLoggedIn = !!token;

  const logInHandler = (token, userModel) => {
    setToken(token);
    setUserName(userModel.username);
    setProblemPoints(userModel.problemPoints);
    setCompetitionPoints(userModel.setCompetitionPoints);
    setImage(userModel.image);
    setUserId(userModel.id);
    setEmail(userModel.email);
    setName(userModel.name);
    setBio(userModel.bio);
    setProfession(userModel.profession);
    setLocation(userModel.location);
    localStorage.setItem('username', userModel.username);
    localStorage.setItem('token', token);
    localStorage.setItem('problemPoints', userModel.problemPoints);
    localStorage.setItem('competitionPoints', userModel.competitionPoints);
    localStorage.setItem('image', userModel.image);
    localStorage.setItem('userId', userModel.id);
    localStorage.setItem('email', userModel.email);
    localStorage.setItem('name', userModel.name);
    localStorage.setItem('bio', userModel.bio);
    localStorage.setItem('profession', userModel.profession);
    localStorage.setItem('location', userModel.location);
  };

  const logOutHandler = () => {
    setToken(null);
    setUserName('');
    setProblemPoints(0);
    setCompetitionPoints(0);
    setImage("");
    setUserId(0);
    localStorage.removeItem('username');
    localStorage.removeItem('token');
    localStorage.removeItem('problemPoints');
    localStorage.removeItem('competitionPoints');
    localStorage.removeItem('image');
    localStorage.removeItem('roles');
    localStorage.removeItem('userId');
    localStorage.removeItem('email');
    localStorage.removeItem('name');
    localStorage.removeItem('bio');
    localStorage.removeItem('profession');
    localStorage.removeItem('location');
  };

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    login: logInHandler,
    logout: logOutHandler,
    username: username,
    problemPoints: problemPoints,
    competitionPoints: competitionPoints,
    image: image,
    userId: userId,
    problemPointsHandler: setProblemPoints,
    email: email,
    name: name,
    bio: bio,
    profession: profession,
    location: location
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
