import EndPoints from "../../../Store/API/EndPoints.json";

export async function LogInRequest(event, username, password) {
  // Prevent the refresh
  event.preventDefault();

  var url = EndPoints.SignIn;

  var urlWithEmail = url.replace("{usernameentry}", username);
  let urlWithEmailAndPassword = urlWithEmail.replace("{passwordentry}", password);

  url = urlWithEmailAndPassword;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    if (response.status === 403) {
      return "Incorrect Details";
    }
  }

  const data = await response.json();

  return data;
}

export async function RegisterRequest(
  event,
  username,
  password,
  userProfilePicture,
  email,
  name,
  bio,
  profession,
  location
) {
  // Prevent the refresh
  event.preventDefault();

  var url = EndPoints.Register;

  const body = JSON.stringify({
    id: 0,
    username: username,
    password: password,
    problemPoints: 0,
    competitionPoints: 0,
    image: userProfilePicture,
    email: email,
    name: name,
    bio: bio,
    profession: profession,
    location: location
  });

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: body,
  });

  if (!response.ok) {
    return "Error";
  }

  const data = await response.json();

  return data;
}
