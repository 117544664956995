import styles from "./LogInFormStyles.module.css";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../Store/Context/AuthContext";
import {
  LogInRequest,
  RegisterRequest,
} from "./LogInAndRegister/LogInAndRegister";
import React, { useState, useEffect, useContext } from "react";
import LogInForm from "./Forms/LogInForm";
import RegisterForm from "./Forms/RegisterForm";

const LogInRegisterForm = (props) => {
  const navigate = useNavigate();

  const authCtx = useContext(AuthContext);

  const [isLogIn, setIsLogIn] = useState(props.logIn);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [bio, setBio] = useState("");
  const [profession, setProfession] = useState("");
  const [location, setLocation] = useState("");

  const [userNameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [incorrectDetailsError, setIncorrectDetailsError] = useState(false);

  useEffect(() => {
    setUsernameError(false);
    setPasswordError(false);
    setIncorrectDetailsError(false);
    setEmailError(false);
  }, [isLogIn]);

  const handleFormSubmission = async (event) => {
    if (isLogIn) {
      const data = await LogInRequest(event, username, password);
      if (data === "Incorrect Details") {
        setIncorrectDetailsError(true);
      } else {
        authCtx.login(data.access_token, data.userModel);
        navigate("/problems");
      }
    } else {
      if (email.length === 0 || password.length === 0 || username.length === 0){
        event.preventDefault();
        return;
      }

      const data = await RegisterRequest(event, username, password, image, email, name, bio, profession, location);
      if (data === "Error") {
      } else {
        authCtx.login(data.access_token, data.userModel);
        navigate("/problems");
      }
    }
  };

  const passwordHandler = (event) => {
    const newPassword = event.target.value;

    setPassword(newPassword);

    const validRegex =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

    if (!isLogIn) {
      if (!newPassword.match(validRegex)) {
        setPasswordError(true);
      } else setPasswordError(false);
    }
  };

  const usernameHandler = (event) => {
    const newUsername = event.target.value;

    setUsername(newUsername);
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    if (!isLogIn) {
      if (
        newUsername.length < 8 ||
        newUsername.includes(" ") ||
        format.test(newUsername)
      ) {
        setUsernameError(true);
      } else setUsernameError(false);
    }
  };

  const imageHandler = (e) => {
    let file = e.target.files[0];

    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      var arr = reader.result.split(",");
      setImage(arr[1]);
    };
  };

  const emailHandler = (event) => {
    const newEmail = event.target.value;

    setEmail(newEmail);
    var format = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!isLogIn) {
      if (
        newEmail.includes(" ") ||
        !format.test(newEmail)
      ) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    }
  }

  const formLabel = isLogIn ? "Sign In" : "Register";
  const buttonLabel = isLogIn ? "Register" : "Sign In";

  return (
    <div className={isLogIn ? styles.formContainer : styles.formContainerTwo}>
      {isLogIn && (
        <LogInForm
          usernameHandler={usernameHandler}
          passwordHandler={passwordHandler}
          incorrectDetailsError={incorrectDetailsError}
          formLabel={formLabel}
        >
          <button
            disabled={!isLogIn && (userNameError || passwordError)}
            type="submit"
            onClick={handleFormSubmission}
          >
            {formLabel}
          </button>
          <button onClick={() => setIsLogIn(!isLogIn)}>{buttonLabel}</button>
        </LogInForm>
      )}
      {!isLogIn && (
        <RegisterForm
          usernameHandler={usernameHandler}
          passwordHandler={passwordHandler}
          imageHandler={imageHandler}
          emailHandler={emailHandler}
          userNameError={userNameError}
          passwordError={passwordError}
          emailError={emailError}
          setName={setName}
          setBio={setBio}
          setProfession={setProfession}
          setLocation={setLocation}
          formLabel={formLabel}
        >
          <button
            disabled={!isLogIn && (userNameError || passwordError)}
            type="submit"
            onClick={handleFormSubmission}
          >
            {formLabel}
          </button>
          <button onClick={() => setIsLogIn(!isLogIn)}>{buttonLabel}</button>
        </RegisterForm>
      )}
    </div>
  );
};

export default LogInRegisterForm;
