import React from "react";
import Navigation from "../../Components/Common Components/Navigation/Navigation";
import styles from "./ChallengePageStyles.module.css";
import InformationPanel from "../../Components/ChallangePageComponents/InformationPanel/InformationPanel";
import JoinCreatePanel from "../../Components/ChallangePageComponents/JoinCreatePanel/JoinCreatePanel";
import { useEffect } from "react";

const ChallengePage = () => {  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Navigation />
      <div className={styles.outerContainer}>
      <div className={styles.container}>
        <InformationPanel/>
        <JoinCreatePanel/>
      </div>
      </div>
    </React.Fragment>
  );
};

export default ChallengePage;
