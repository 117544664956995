import Navigation from "../../Components/Common Components/Navigation/Navigation";
import styles from "./ProblemPageStyles.module.css";
import React, { useState, useEffect, useContext } from "react";
import EndPoints from "../../Store/API/EndPoints.json";
import LoadingSpinner from "../../Components/Common Components/LoadingSpinner/LoadingSpinner";
import FilterBar from "../../Components/ProblemPageComponents/FilterBar/Filter";
import ProblemBar from "../../Components/ProblemPageComponents/ProblemBar/ProblemBar";
import UserBar from "../../Components/ProblemPageComponents/UserBar/UserBar";
import AuthContext from "../../Store/Context/AuthContext";

const ProblemPage = () => {
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [problemsList, setProblemLists] = useState([]);
  const [mutatedProblemsList, setMutatedProblemLists] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [filter, setFilter] = useState(
    localStorage.getItem("problemFilter") ?? "All"
  );

  const setNewFilter = (newFilter) => {
    localStorage.setItem("problemFilter", newFilter);
    setFilter(newFilter);
    if (newFilter === "All") setMutatedProblemLists(problemsList);
    else
      setMutatedProblemLists(
        problemsList.filter((problem) =>
          filterProblemWithTags(problem, newFilter)
        )
      );
  };

  useEffect(() => {

    var url = EndPoints.GetAllProblems;
    if (authCtx.isLoggedIn) {
      url = EndPoints.GetAllProblemsLoggedIn.replace("1", authCtx.userId);
    }

    fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
        }
      })
      .then((data) => {
        setProblemLists(data);
        setLoading(false);
        if (localStorage.getItem("problemFilter") !== null) {
          setMutatedProblemLists(
            data.filter((problem) =>
              filterProblemWithTags(
                problem,
                localStorage.getItem("problemFilter")
              )
            )
          );
        } else {
          setMutatedProblemLists(data);
        }
      })
      .catch((err) => {
      });
  }, []);

  if (isLoading) {
    return (
      <React.Fragment>
        <Navigation />
        <LoadingSpinner />;
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Navigation />
      <div className={styles.outerContainer}>
        <div className={styles.container}>
          <div>
            <UserBar />
            <FilterBar setFilter={setNewFilter} activeFilter={filter} />
          </div>
          <div>
            {mutatedProblemsList.map((problem) => (
              <React.Fragment>
                <ProblemBar key={problem.name} data={problem} />
              </React.Fragment>
            ))}
            {mutatedProblemsList.length === 0 && (
              <h1 className={styles.noProblemsMsg}>
                Looks like there is no problems in this filter. We are working
                hard to add more problems. Please select another filter
              </h1>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

function filterProblemWithTags(problem, newFilter) {
  if (newFilter === "All") return true;
  return problem.tags.find((obj) => {
    return obj.tag === newFilter;
  });
}

export default ProblemPage;
