import LandingImage from "../../../Store/Images/HomePageImages/LandingImage.png";
import styles from "./LandingSectionStyles.module.css";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../Store/Context/AuthContext";
import React, { useContext } from "react";

const LandingSection = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  return (
    <div className={styles.landingSection}>
      <div className={styles.landingSectionGrid}>
        <div className={styles.introTextGrid}>
          <div className={styles.miniNav}>
            <h3>Codenemy</h3>
            {authContext.isLoggedIn && (
              <button onClick={() => navigate("/challenge")}>
                Start Challenging Now
              </button>
            )}
            {!authContext.isLoggedIn && (
              <button
                onClick={() => navigate("/login", { state: { logIn: true } })}
              >
                Start Challenging Now
              </button>
            )}
          </div>
          <div className={styles.titleSec}>
            <h1>Codenemy.</h1>
            <h2>Improve through Challenge</h2>
            <p>
              It's no secret that your friends are apart of your motivation.
              Introducing Codenemy, where you can challenge your friends, track
              your progression & earn badges
            </p>
            {authContext.isLoggedIn && (
              <React.Fragment>
                <button onClick={() => navigate("/problems")}>Sign In</button>
                <button onClick={() => navigate("/problems")}>Register</button>
              </React.Fragment>
            )}
            {!authContext.isLoggedIn && (
              <React.Fragment>
                <button
                  onClick={() => navigate("/login", { state: { logIn: true } })}
                >
                  Sign In
                </button>
                <button
                  onClick={() =>
                    navigate("/login", { state: { logIn: false } })
                  }
                >
                  Register
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className={styles.imgSec}>
          <img src={LandingImage} alt="Hacker" />
        </div>
      </div>
    </div>
  );
};

export default LandingSection;
