import styles from "./userbarstyles.module.css";
import AuthContext from "../../../Store/Context/AuthContext";
import { useContext } from "react";

const UserBar = () => {
  const authCtx = useContext(AuthContext);

  if (!authCtx.isLoggedIn) return;

  return (
    <div className={styles.filters}>
      <h1>{authCtx.username}</h1>
      <h3>Problem Points - {authCtx.problemPoints}</h3>
      <h3>Competition Points - {authCtx.competitionPoints}</h3>
    </div>
  );
};

export default UserBar;
