import styles from "./BoxStyles.module.css";
import Logo from "../Logo/Logo";

const Box = (props) => {
  return (
    <div
      className={`${styles.box} ${props.className} ${
        props.alignMiddle ? styles.alignMiddle : styles.alignLeft
      }`}
    >
      <h1 className={styles.title}>{props.title}</h1>
      {props.icon && <Logo>{props.icon}</Logo>}
      {props.description && (
        <h3 className={styles.description}>{props.description}</h3>
      )}
      {props.img && (
        <img
          src={props.img}
          alt="feedback"
          className={props.fitImage ? styles.boxImageStyle : undefined}
        />
      )}
      {props.children}
    </div>
  );
};

export default Box;
