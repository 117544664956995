import Styles from "./problembarstyles.module.css";
import { useNavigate } from "react-router-dom";

const ProblemBar = (props) => {
  const navigation = useNavigate();

  const clickHanlder = (id) => {
    navigation("problem/" + id, { state: { problemData: props.data } });
  };

  const difficultyClassName = difficultyClass(props.data.difficulty);
  const solvedStatus = props.data.solved ? "Solved" : "Not Solved"; 

  return (
    <div className={Styles.problemBar}>
      <h2 className={Styles.problemTitle}>{props.data.name}</h2>
      <div className={Styles.description}>
        <h4 className={difficultyClassName}>{props.data.difficulty}</h4>
        <h4 style={ props.data.solved ? {color: "green"} : {color : "red"}}>{solvedStatus}</h4>
        {props.data.tags.map((tag) => (
          <p key={tag.id}>{tag.tag}</p>
        ))}
      </div>
      <button
        className={Styles.problemBtn}
        onClick={() => clickHanlder(props.data.id)}
      >
        Solve Problem
      </button>
    </div>
  );
};

function difficultyClass(difficulty) {
  if (difficulty === "Easy") return Styles.easy;
  else if (difficulty === "Medium") return Styles.medium;
  else return Styles.hard;
}

export default ProblemBar;
