import Box from "../../Common Components/Box/Box";
import styles from "./TargetSectionStyles.module.css";
import { GiPerspectiveDiceFive } from "react-icons/gi";
import { FaBook, FaUserFriends} from "react-icons/fa";
import { BsFillKeyFill } from "react-icons/bs";
import Logo from "../../Common Components/Logo/Logo";

const TargetSection = () => {
  return (
    <div className={styles.gridThree}>
      <h1> What Codenemy allows you to do </h1>
      <Logo><BsFillKeyFill/></Logo>
      <div className={styles.innerGridThree}>
        <Box
          title={"Gain new perspectives"}
          icon={<GiPerspectiveDiceFive/>}
          alignMiddle={true}
          className={styles.targetBox}
        >
            <p>Improve your coding skills on Codenemy by tackling a diverse range of challenges, comparing solutions with other users, learning from your peers and taking your abilities to new heights</p>
        </Box>
        <Box
          title={"Learn new languages"}
          icon={<FaBook/>}
          alignMiddle={true}
          className={styles.targetBox}
        >
            <p>Unlock the power of coding by expanding your knowledge and diversifying your skill set. With Codenemy, you can explore new languages, become multilingual in coding, and take your skills to the next level.</p>
        </Box>
        <Box
          title={"Compete with friends"}
          icon={<FaUserFriends/>}
          alignMiddle={true}
          className={styles.targetBox}
        >
            <p>Test your coding skills against friends on Codenemy. Engage in friendly competition, race to the top of the leaderboard, and improve with a variety of challenges. Have fun while improving your coding abilities.</p>
        </Box>
      </div>
    </div>
  );
};

export default TargetSection;
