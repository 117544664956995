import FeedbackImg from "../../../Store/Images/HomePageImages/ExampleImage.png";
import styles from "./LearningSectionStyles.module.css";
import Box from "../../Common Components/Box/Box";
import Logo from "../../Common Components/Logo/Logo";
import { FaGraduationCap } from "react-icons/fa";
import { MdFeedback } from "react-icons/md";
import { BiGitCompare } from "react-icons/bi";
import { GiSharpAxe } from "react-icons/gi";

const LearningSection = () => {
  return (
    <div className={styles.gridTwo}>
      <h1> Learning </h1>
      <Logo>
        <FaGraduationCap />
      </Logo>
      <div className={styles.innerGridTwo}>
        <Box
          title={"Get Instant Feedback"}
          icon={<MdFeedback/>}
          description={
            "Realize your coding potential with our one-of-a-kind instant feedback. Receive live evaluation and compare against peers. Get specific and detailed feedback on your solutions to correct errors quickly, and improve coding skills at a faster rate. Join now for instant feedback benefits on your coding journey."
          }
          fitImage={true}
          alignMiddle={false}
          className={styles.rowOneColumnOne}
        />
        <Box
          title={"Compare Your Code"}
          icon={<BiGitCompare/>}
          description={
            "Elevate your coding with our innovative comparison feature. Measure your solutions against peers, learn from their techniques, and explore diverse approaches to problem-solving. Analyze varied solutions to a problem, uncovering new insights, and refining skills in a competitive, collaborative environment. Experience our comparison feature and upgrade your coding skills."
          }
          fitImage={true}
          alignMiddle={false}
          className={styles.rowOneColumnTwo}
        />
        <Box
          title={"Sharpen your coding skills"}
          icon={<GiSharpAxe/>}
          description={
            "Our platform currently offers two popular programming languages, Java and Python, allowing you to sharpen your skills in these languages. We are constantly working to expand our problem library to include more languages in the future. With our platform, you can improve your coding skills in two of the most widely used languages in the industry. We offer a variety of problems and challenges to test your knowledge and help you advance your skills in these languages."
          }
          alignMiddle={false}
          className={styles.rowOneColumnThree}
        />
      </div>
    </div>
  );
};

export default LearningSection;
