import { useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../Components/Common Components/LoadingSpinner/LoadingSpinner";
import EndPoints from "../../Store/API/EndPoints.json";
import Description from "../../Components/IndividualProblemPageComponents/Description/Description";
import IDE from "../../Components/IndividualProblemPageComponents/IDE/IDE";
import Output from "../../Components/IndividualProblemPageComponents/Output/Output";
import styles from "./IndividualProblemPageStyles.module.css";
import LanguageSelector from "../../Components/IndividualProblemPageComponents/LanguageSelector/LanguageSelector";

const IndividualProblemPage = () => {
  const { state } = useLocation();
  var { problemId } = useParams();

  const [isLoading, setLoading] = useState(true);
  const [problemData, setProblemData] = useState();
  const [problemLanguage, setProblemLanguage] = useState(
    localStorage.getItem("preferredLanguage") ?? "Java"
  );
  const [problemCode, setProblemCode] = useState("");
  const [originalCode, setOriginalCode] = useState("");
  const [solved, setSolved] = useState("");

  useEffect(() => {
    if (state === null) {
      var url = EndPoints.GetProblem;
      url.replace("1", problemId);

      fetch(
        EndPoints.GetProblem.replace("{1}", problemId).replace(
          "{2}",
          problemLanguage
        ),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
          }
        })
        .then((data) => {
          setProblemData(data);
          const arrOfProblemLanguages = data.problemLanguages;
          const filteredArr = arrOfProblemLanguages.filter(
            (element) =>
              element.language.programmingLanguage === problemLanguage
          );
          setProblemCode(filteredArr[0].code);
          setOriginalCode(filteredArr[0].code);
          setLoading(false);
          setSolved(data.solved)
        })
        .catch((err) => {
          
        });
    } else {
      setProblemData(state.problemData);
      const arrOfProblemLanguages = state.problemData.problemLanguages;
      const filteredArr = arrOfProblemLanguages.filter(
        (element) => element.language.programmingLanguage === problemLanguage
      );
      setProblemCode(filteredArr[0].code);
      setOriginalCode(filteredArr[0].code);
      setLoading(false);
      setSolved(state.problemData.solved);
    }
  }, [problemLanguage, problemId, state]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <React.Fragment>
      <div className={styles.container}>
        <LanguageSelector 
          updateProblemCode={setProblemCode} 
          problemCode={originalCode} 
          setLanguage={setProblemLanguage} 
          lang={problemLanguage}
          name={problemData.name}
          isSolved={solved}
          competition={false}
        />
        <div>
          <Description data={problemData} />
        </div>
        <div>
          <IDE
            className="IDE"
            currentLanguage={problemLanguage}
            currentProblemName={problemData.name}
            code={problemCode}
            updateProblemCode={setProblemCode}
            competition={false}
          />
          <Output
            code={problemCode}
            currentLanguage={problemLanguage}
            problem={problemData}
            updateSolved={setSolved}
            competition={false}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default IndividualProblemPage;
