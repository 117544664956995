import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../Components/Common Components/LoadingSpinner/LoadingSpinner";
import EndPoints from "../../Store/API/EndPoints.json";
import Description from "../../Components/IndividualProblemPageComponents/Description/Description";
import IDE from "../../Components/IndividualProblemPageComponents/IDE/IDE";
import Output from "../../Components/IndividualProblemPageComponents/Output/Output";
import styles from "./ChallengeProblemPageStyles.module.css";
import LanguageSelector from "../../Components/IndividualProblemPageComponents/LanguageSelector/LanguageSelector";
import UserPanel from "../../Components/ChallengeProblemPageComponents/UserPanel";

const ChallengeProblemPage = (props) => {
  const [isLoading, setLoading] = useState(true);
  const [problemData, setProblemData] = useState();
  const [problemLanguage, setProblemLanguage] = useState(
    localStorage.getItem("preferredLanguage") ?? "Java"
  );
  const [problemCode, setProblemCode] = useState("");
  const [originalCode, setOriginalCode] = useState("");
  const [solved, setSolved] = useState("");

  useEffect(() => {
    fetch(
      EndPoints.GetProblem.replace("1", props.problemId),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
        }
      })
      .then((data) => {
        setProblemData(data);
        const arrOfProblemLanguages = data.problemLanguages;
        const filteredArr = arrOfProblemLanguages.filter(
          (element) => element.language.programmingLanguage === problemLanguage
        );
        setProblemCode(filteredArr[0].code);
        setOriginalCode(filteredArr[0].code);
        setLoading(false);
        setSolved(data.solved);
      })
      .catch((err) => {});
  }, [problemLanguage, props.problemId]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <React.Fragment>
      <div className={styles.container}>
        <LanguageSelector 
          updateProblemCode={setProblemCode}
          problemCode={originalCode}
          setLanguage={setProblemLanguage}
          lang={problemLanguage}
          name={problemData.name}
          isSolved={solved}
          competition={true}
        />
        <div>
          <UserPanel
            users={props.users}
          />
          <Description data={problemData} />
        </div>
        <div>
          <IDE
            className="IDE"
            currentLanguage={problemLanguage}
            currentProblemName={problemData.name}
            code={problemCode}
            updateProblemCode={setProblemCode}
            competition={true}
          />
          <Output
            updatePercentageHandler={props.updatePercentageHandler}
            code={problemCode}
            currentLanguage={problemLanguage}
            problem={problemData}
            updateSolved={setSolved}
            competition={true}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChallengeProblemPage;
