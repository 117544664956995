import React, { useContext } from "react";
import styles from "./selectorstyles.module.css";
import { BiReset, BiArrowBack, BiCheck, BiX } from "react-icons/bi";
import Logo from "../../Common Components/Logo/Logo";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../Store/Context/AuthContext";

const LanguageSelector = (props) => {
  const authCtx = useContext(AuthContext);

  const navigation = useNavigate();

  const languageSelectorHandler = (event) => {
    localStorage.setItem("preferredLanguage", event.target.value);
    props.setLanguage(event.target.value);
  };

  const resetButtonHandler = () => {
    localStorage.setItem(
      "codeFor-" + props.name + props.lang,
      props.problemCode
    );
    props.updateProblemCode(props.problemCode);
  };

  const backArrowHandler = () => {
    navigation("/problems");
  };

  return (
    <div className={styles.navSection}>
      <div className={styles.backButton}>
        <Logo className={styles.logo} clickHandler={backArrowHandler}>
          <BiArrowBack />
        </Logo>
      </div>
      <div className={styles.rightSection}>
        {authCtx.isLoggedIn && props.isSolved && !props.competition && (
        <React.Fragment>
          <h3 id={styles.solvedLogoText}> Solved 
          <Logo className={styles.solvedLogo}>
            <BiCheck/>
          </Logo>
          </h3>
        </React.Fragment>) }
        {authCtx.isLoggedIn && !props.isSolved && !props.competition &&
        <h3 id={styles.unsolvedLogoText}> Not Solved 
          <Logo className={styles.unsolvedLogo}>
            <BiX/>
          </Logo>
        </h3>}
        <select
          value={props.lang}
          onChange={languageSelectorHandler}
          className={styles.langSelector}
        >
          <option value="Java">Java</option>
          <option value="python3">Python</option>
          <option value="javascript">JavaScript</option>
        </select>
        <Logo className={styles.logo} clickHandler={resetButtonHandler}>
          <BiReset />
        </Logo>
      </div>
    </div>
  );
};

export default LanguageSelector;
