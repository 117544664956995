import React from "react";
import styles from "./UserScoringPanelStyles.module.css";
import { GiSpeedometer, GiTimeBomb } from "react-icons/gi";
import { BsFillFileCodeFill } from "react-icons/bs";

const languages = new Map([
  ["python3", "Python"],
  ["Java", "Java"],
  ["javascript", "JavaScript"],
]);

const UserScoringPanel = (props) => {
  // sort the users by score.
  const sortedUsers = props.users.sort((a, b) => {
    if (b.percentage !== a.percentage) {
      return b.percentage - a.percentage; // Sort by percentage in descending order
    } else {
      return a.timeTaken - b.timeTaken; // Sort by time in ascending order for tiebreakers
    }
  });
  //

  var position = 1;

  return sortedUsers.map((user) => (
    <React.Fragment>
      <div className={styles.individualUserScore} key={user.username}>
        <div className={styles.position}>
          <h1>{position++}</h1>
        </div>
        <img src={`data:image/png;base64,${user.image}`} alt="profile pic" />
        <p className={styles.responsiveUsername}>{user.username}</p>
        <div className={styles.infomration}>
          <div className={styles.username}>
            <p>{user.username}</p>
          </div>
          <div className={styles.metrics}>
            <GiSpeedometer
              style={{ fontSize: "2rem" }}
              className={styles.icon}
            />
            <div>
              <p>{user.percentage}%</p>
              <p>SCORE</p>
            </div>
          </div>
          <div className={styles.metrics}>
            <GiTimeBomb style={{ fontSize: "2rem" }} className={styles.icon} />
            <div>
              <p>{user.timeTaken}</p>
              <p>TIME</p>
            </div>
          </div>
          <div className={styles.metrics}>
            <BsFillFileCodeFill
              style={{ fontSize: "2rem" }}
              className={styles.icon}
            />
            <div>
              <p>LANGUAGE</p>
              <p>{languages.get(user.language)}</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ));
};

export default UserScoringPanel;
