import LoadingSpinner from "../../Common Components/LoadingSpinner/LoadingSpinner";
import Styles from "./outputbarstyles.module.css";

const TestCaseResponse = (props) => {
  if (props.isLoading) return <LoadingSpinner notFullSize={true}/>;

  if (props.testCaseResponse === null && !props.isLoading) {
    return;
  }

  if (props.testCaseResponse.error.length > 0 && !props.isLoading) {
    return (
      <div className={Styles.responseSection}>
      <div className={Styles.subSection}>
        <h3 className={Styles.label}>Compiler Message - Error</h3>
        <div className={Styles.errorDiv}>
        {props.testCaseResponse.error.map((val) => (
              <p> {val} </p>
            ))}
        </div>
      </div>
    </div>
    )
  }
  
  var messageStyleObject = {};

  if (props.testCaseResponse.pass) messageStyleObject = { color: "green" };
  else messageStyleObject = { color: "red" };

  return (
    <div className={Styles.responseSection}>
      <div className={Styles.subSection}>
        <h3 className={Styles.label}>Compiler Message</h3>
        <div className={Styles.resultDiv}>
          <h3 className={Styles.result} style={messageStyleObject}>
            {props.testCaseResponse.pass ? "Right Answer" : "Wrong Answer"}
          </h3>
        </div>
      </div>
      <div className={Styles.subSection}>
        <h3 className={Styles.label}>Input (stdin)</h3>
        <div className={Styles.resultDiv}>
          <h3 className={Styles.result}>{props.testCaseResponse.input}</h3>
        </div>
      </div>
      <div className={Styles.subSection}>
        <h3 className={Styles.label}>Your Output (stdout)</h3>
        <div className={Styles.resultDiv}>
          <h3 className={Styles.result}>{props.testCaseResponse.userOutput}</h3>
        </div>
      </div>
      {props.testCaseResponse.stdOut.length > 0 && (
        <div className={Styles.subSection}>
          <h3 className={Styles.label}>Debugging</h3>
          <div className={Styles.resultDiv}>
            {props.testCaseResponse.stdOut.map((val) => (
              <h3> {val + "\n"} </h3>
            ))}
          </div>
        </div>
      )}
      <div className={Styles.subSection}>
        <h3 className={Styles.label}>Expected Output </h3>
        <div className={Styles.resultDiv}>
          <h3 className={Styles.result}>
            {props.testCaseResponse.expectedOutput}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default TestCaseResponse;
